html,
body,
#root {
  height: 100%;
}

.table-call tbody {
  cursor: pointer;
}

.call-person {
  border-top: 1px solid #dee2e6;
  cursor: pointer;
}
.call-person:hover {
  background-color: rgba(0, 0, 0, 0.075);
}

.navbar-nav .dropdown-menu {
  position: absolute !important;
}

.modal-body .table th {
  border-top: none;
}

.tab-nums {
  font-feature-settings: 'tnum';
  font-variant-numeric: tabular-nums;
}

.redeemed {
  color: #6c757d !important;
  text-decoration: line-through;
}

.table-auto {
  width: auto;
}
